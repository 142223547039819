
import { defineComponent } from 'vue'
import DataService from '@/services/DataService'
import iMovies from '@/types/Movies'
import ResponseData from '@/types/ResponseData'

export default defineComponent({
  name: 'update',
  components: {},
  data() {
    return {
      title: '',
      year: 1990,
      director: '',
      posterUrl: '',
      updated: false,
      deleted: false,
      submitted: false,
      errors: [],
    }
  },
  methods: {
    getMovie(id: any) {
      DataService.get(id)
        .then((response: ResponseData) => {
          this.title = response.data.title
          this.year = response.data.year
          this.director = response.data.director
          this.posterUrl = response.data.posterUrl
        })
        .catch((e: Error) => {
          console.log(e)
        })
    },
    deleteMovie(id: number) {
      DataService.delete(id)
        .then((response: ResponseData) => {
          console.log(response.data)
          this.deleted = true
          this.updated = false
          this.submitted = true
          setTimeout(() => {
            this.$router.push({ name: 'Home' })
          }, 2000)
        })
        .catch((e: Error) => {
          console.log(e)
        })
    },
    updateMovie(id: number) {
      let data = {
        title: this.title,
        year: this.year,
        director: this.director,
        posterUrl: this.posterUrl,
      }
      DataService.update(id, data)
        .then((response: ResponseData) => {
          console.log(response.data)
          this.updated = true
          this.deleted = false
          this.submitted = true
        })
        .catch((e: Error) => {
          console.log(e)
        })
    },
    checkForm: function (e) {
      this.errors = []

      if (!this.title) {
        this.errors.push('Titre requis')
      }
      if (!this.year) {
        this.errors.push('Année requise')
      }
      if (!this.director) {
        this.errors.push('Réalisateur requis')
      }
      if (!this.posterUrl) {
        this.errors.push('Affiche requise')
      }

      if (!this.errors.length) {
        this.updateMovie(this.$route.params.id)
      }

      e.preventDefault()
    },
  },
  mounted() {
    this.getMovie(this.$route.params.id)
  },
})
